<script>
export default {
	name: 'MobileFilterBtn',
	methods: {
		toggleDrawer() {
			this.$emit('toggleDrawer')
		},
	},
}
</script>

<template>
	<div>
		<v-bottom-navigation grow fixed class="gold">
			<Button @click="toggleDrawer" class="ml-0 pl-0" text plain :ripple="false">
				<v-icon color="white"> mdi-filter </v-icon>
				<span class="white--text"> FILTRAR/ORDENAR </span>
			</Button>
		</v-bottom-navigation>
	</div>
</template>
