<script>
export default {
	name: 'ShopFiltersGroup',
	props: {
		title: String,
		items: Array,
		value: Array,
	},
	computed: {
		textValue: {
			get() {
				return this.value
			},
			set(newValue) {
				this.$emit('filterClick', newValue)
			},
		},
	},
}
</script>

<template>
	<div class="d-flex align-center">
		<div class="d-none d-sm-block text-uppercase font-weight-bold t1 mr-3">Ordenar por...</div>
		<v-select
			:items="items"
			v-model="textValue"
			:placeholder="textValue.length ? textValue[0].text : 'Ordenar por...'"
			color="transparent"
			append-icon="mdi-chevron-down gold--text"
			style="max-width: 20rem"
		/>
	</div>
</template>
<style scoped>
.v-item--active {
	padding: 2px !important;
}
</style>
