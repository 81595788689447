<script>
export default {
	name: 'ShopFiltersGroup',
	inject: ['getFilterRoute'],
	props: {
		title: String,
		items: Array,
		onlyButton: Boolean,
		type: String,
		limit: {
			type: Number,
			default: 12,
		},
	},
	data() {
		return {
			dialog: false,
		}
	},
	computed: {
		listItems() {
			return [...(this.items || [])]
				.sort((a, b) => {
					if (a.count == b.count) {
						return a.text > b.text ? 1 : -1
					} else {
						return (b.count || 0) - (a.count || 0)
					}
				})
				.slice(0, this.limit)
		},
		itemsByLetter() {
			if (this.items?.length <= this.limit) return null
			let items = [...this.items].sort((a, b) => {
				return a.text > b.text ? 1 : -1
			})
			return items.reduce((obj, item) => {
				let letter = item.text.slice(0, 1).toUpperCase()
				if (!obj[letter]) obj[letter] = []
				obj[letter].push(item)
				return obj
			}, {})
		},
	},
}
</script>

<template>
	<div class="mt-6" v-if="listItems.length">
		<div class="font-1 primary--text font-weight-bold text-uppercase pb-1" v-if="title">
			{{ title }}
		</div>
		<div v-for="(item, i) of listItems" :key="i" :class="{ 'py-1': !!item.color }">
			<Button
				:color="item.color ? 'secondary' : 'gray'"
				:text="!item.color"
				:style="{ backgroundColor: item.color }"
				:dark="!!item.color"
				:to="getFilterRoute(type, item.value, true)"
			>
				<span class="font-weight-bold">{{ item.text }}</span>
				<span class="ml-1" v-if="item.count" style="opacity: 0.9"> ({{ item.count }}) </span>
			</Button>
		</div>
		<div v-if="itemsByLetter" class="link--text py-1 pl-4 cpointer" @click="dialog = true">
			Ver más opciones...
		</div>
		<v-dialog v-model="dialog" v-if="itemsByLetter">
			<div class="white px-8" style="positon: relative">
				<div
					class="font-weight-bold font-2 mb-3 d-flex justify-space-between w100 cpointer py-5 white"
					style="position: sticky; top: 0"
					@click="dialog = false"
				>
					<span>{{ title }}</span>
					<v-icon color="link">mdi-close</v-icon>
				</div>
				<div v-for="(items, letter) in itemsByLetter" :key="letter" class="d-flex pb-3">
					<div class="font-weight-bold text-capitalize font-3 mr-5 pt-1">
						{{ letter }}
					</div>
					<div class="w100 pb-3 dialog-items-border">
						<div v-for="(item, i) of items" :key="i" class="font-1 cpointer py-2">
							<router-link :to="getFilterRoute(type, item.value, true)">
								<span class="mr-3">{{ item.text }}</span>
								<span class="grey--text">({{ item.count }})</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>
<style scoped>
.v-item--active {
	padding: 2px !important;
}
.v-dialog__content ::v-deep .v-dialog {
	min-width: 400px;
	max-width: 600px;
	width: auto;
}
.dialog-items-border {
	border-bottom: 2px solid whitesmoke;
}
.cpointer {
	cursor: pointer;
}
</style>
